import { BehaviorSubject, Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { without } from 'lodash';

import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { IEnvironment } from './IEnvironment';
import { INavbarItem } from '../layout/navbar/INavbarItem';
import { LiveStage } from './LiveStage';

@Injectable()
export class LiveStageService {
    liveStage: Observable<LiveStage>;
    listeners: Observable<any[]>;
    private liveStageSubject: BehaviorSubject<LiveStage>;
    private listenersSubject: BehaviorSubject<any[]>;

    constructor(@Inject(ENVIRONMENT) private environment: IEnvironment) {
        var liveStage =
                LiveStage[
                    window.localStorage.getItem('LiveStageService.liveStage') as LiveStage
                ] || LiveStage.live,
            liveStageSubject = new BehaviorSubject(liveStage),
            listenersSubject = new BehaviorSubject([]);

        this.liveStage = liveStageSubject.asObservable();
        this.liveStageSubject = liveStageSubject;

        this.listeners = listenersSubject.asObservable();
        this.listenersSubject = listenersSubject;
    }

    addListener(listener: any, liveStageApi: keyof IEnvironment['api']) {
        if (this.hasStageApi(liveStageApi)) {
            let listenersSubject = this.listenersSubject,
                listeners = listenersSubject.getValue();

            listeners = listeners.slice();
            listeners.push(listener);

            listenersSubject.next(listeners);
        }
    }

    get(liveStageApi?: keyof IEnvironment['api']) {
        var liveStage = this.liveStageSubject.getValue();

        if (liveStageApi && !this.hasStageApi(liveStageApi)) {
            liveStage = LiveStage.live;
        }

        return liveStage;
    }

    /**
     * Determine whether an API has a stage URL.
     *
     * @param liveStageApi - API to check.
     */
    hasStageApi(liveStageApi: keyof IEnvironment['api']) {
        var hasStageApi = false;

        if (liveStageApi) {
            let api = this.environment.api[liveStageApi],
                stageApi = api && (api as { stage: string }).stage;

            if (stageApi && !stageApi.startsWith('#{')) {
                hasStageApi = true;
            }
        }

        return hasStageApi;
    }

    removeListener(listener: any) {
        var listenersSubject = this.listenersSubject,
            listeners = listenersSubject.getValue();

        listeners = without(listeners, listener);

        listenersSubject.next(listeners);
    }

    set(liveStage: LiveStage) {
        liveStage = liveStage || LiveStage.live;

        var behaviorSubject = this.liveStageSubject;
        if (behaviorSubject.getValue() !== liveStage) {
            behaviorSubject.next(liveStage);
            window.localStorage.setItem('LiveStageService.liveStage', liveStage);
        }
        window.sessionStorage.removeItem('tenaSelectedCountry');
        window.sessionStorage.removeItem('tenaSelectedSegment');

        window.sessionStorage.removeItem('leukoplastSelectedCountry');
        window.sessionStorage.removeItem('leukoplastSelectedSegment');

        window.sessionStorage.removeItem('cutimedSelectedCountry');
        window.sessionStorage.removeItem('cutimedSelectedSegment');

        window.sessionStorage.removeItem('actimoveSelectedCountry');
        window.sessionStorage.removeItem('actimoveSelectedSegment');
    }

    toggle() {
        var liveStage = this.liveStageSubject.getValue();

        if (liveStage === LiveStage.stage) {
            liveStage = LiveStage.live;
        } else {
            liveStage = LiveStage.stage;
        }

        this.set(liveStage);
    }
}
